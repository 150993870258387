/* eslint-disable no-unused-vars */
import { validate } from "gerador-validador-cpf";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import ButtonNovo from "../../component/ButtonRed";
import Comentarios from "../../component/comentarios";
import Admissao from "../../component/DetalhesAtendimento/Admissao";
import Demissao from "../../component/DetalhesAtendimento/Demissao";
import MudancaFuncao from "../../component/DetalhesAtendimento/MudancaFuncao";
import api from "../../services/api";
import FileUpload from "../upload";
import SancaoDisciplinar from "../../component/DetalhesAtendimento/SancaoDisciplinar";
import Atestados from "../../component/DetalhesAtendimento/Atestados";
import Premiacao from "../../component/DetalhesAtendimento/Premiacoes";
import Bonus from "../../component/DetalhesAtendimento/Bonus";
import Diversos from "../../component/DetalhesAtendimento/Diversos";
import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tabs,
  TextField,
  Typography,
  Box,
  Tab,
  useTheme,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";

import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import { Check } from "@material-ui/icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function InteragirAtendimento({ match, history }) {
  const classes = useStyles();
  const theme = useTheme();

  const [labelWidth] = React.useState(0);

  let id = match.params.id;

  const [data, setData] = useState({});
  const [novoComentario, setNovoComentario] = useState("");
  const [cpf, setCpf] = useState("");
  const [loginData, setLoginData] = useState([]);
  const [userAgente, setUserAgente] = useState("");

  const [participantes, setParticipantes] = useState([]);
  const [getParticipants, setGetParticipants] = useState([]);

  const dataLocalStorage = JSON.parse(localStorage.getItem("login"));

  const agente = dataLocalStorage === null ? false : dataLocalStorage.agente;
  const gestor = dataLocalStorage === null ? false : dataLocalStorage.gestor;

  const animatedComponents = makeAnimated();

  const Toast = Swal.mixin({
    toast: true,
    position: "bottom",
    showConfirmButton: false,
    timer: 4000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/login`);

      setLoginData(
        response.data.map((item) => {
          return {
            ...item,
            id: String(item.id),
          };
        })
      );
    }

    fetchData();
  }, [data.user_responsavel]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`/tiposatendimento/${id}/atendimentoid`);

      setData(response.data);
    }
    fetchData();
  }, [id]);

  useEffect(() => {
    async function GetParticipants() {
      const { data: dataParticipantes } = await api.get(`/participantes/${id}`);

      const participantesComNome = await dataParticipantes.map((parti) => {
        const usuario = loginData.find(
          (usuarios) => Number(usuarios.id) === parti.usuario_id
        );

        return {
          value: parti.usuario_id,
          label: usuario ? usuario.nome.toUpperCase() : "Nome não encontrado", // Se o usuário for encontrado, retorna o nome, senão retorna uma string padrão
          email: usuario?.email,
          nome: usuario?.nome,
        };
      });

      setGetParticipants(participantesComNome);

      setParticipantes(participantesComNome);
    }

    GetParticipants();
  }, [id, loginData]);

  let tipoAtendimento = data.tipo_atendimento || "";

  const handleChangeUser = async (event) => {
    await api.put(`/atendimento/${id}`, {
      atendimento_id: id,
      user_responsavel: event.target.value,
    });

    Swal.fire({
      //position: 'top-start',
      icon: "success",
      title: `Agente Alterado com sucesso.`,
      showConfirmButton: false,
      timer: 2000,
    });
    setUserAgente(event.target.value);
  };

  function handleHistoryBack(e) {
    e.preventDefault();
    history.push("/dashboard");
  }

  async function handleCloseAtendimento(e) {
    e.preventDefault();

    Swal.fire({
      title: "FINALIZAR ANTEDIMENTO?",
      text: "Realmente deseja finalizer este atendimento?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      reverseButtons: true,
      confirmButtonText: "Sim, Finalizar",
      cancelButtonText: "NÃO",
    }).then((result) => {
      if (!result.isConfirmed) {
        return;
      }
      api
        .put(`/atendimentoagente/${id}`, {
          status: "FINALIZADO",
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Atendimento Finalizado com sucesso.",
            allowEnterKey: false,
            customClass: {
              container: "my-swal",
            },
          });
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao finalizar o atendimento.",
            customClass: {
              container: "my-swal",
            },
          });
        });
    });

    // await api.put(`/atendimentoagente/${id}`, {
    //   status: 'FINALIZADO',
    // });
  }

  async function handleNewComment(e) {
    e.preventDefault();
    if (novoComentario.length === 0) {
      Toast.fire({
        icon: "error",
        title: "Não é possível fazer comentário com campo em branco ou vazio.",
      });
    }
    if (novoComentario.length !== 0) {
      await api.post(`atendimento/${id}/usuario/${dataLocalStorage.id}`, {
        mensagem: novoComentario,
      });

      window.location.reload(false);
    }
  }

  //const [value, setValue] = React.useState(<Comentarios id={id} />);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleChangeCpf(e) {
    e.preventDefault();
    if (validate(cpf)) {
      await api.put(`/atendimento/${id}`, {
        atendimento_id: id,
        cpf: cpf,
      });

      Toast.fire({
        icon: "success",
        title: "CPF Cadastrado com sucesso.",
      });

      window.location.reload(false);
    } else {
      Toast.fire({
        icon: "error",
        title: "O CPF Informanodo não é valido.",
      });
    }
  }

  //Melhorar logica de usuário logado....
  if (JSON.parse(localStorage.getItem("login")) === null) {
    return <Redirect to="/" />;
  }

  const typesServices = [6, 7, 8, 9, 12];

  async function createParticipants(e) {
    e.preventDefault();

    // Aguarda a remoção antes de prosseguir
    try {
      await handleRemoveParticipant();

      console.log(participantes);

      const { data } = await api.post("/create/participante", {
        id_atendimento: id,
        userData: participantes,
      });

      if (data.msg) {
        toast(data.msg, { type: "info" });
      }
    } catch (error) {
      console.error("Erro ao criar participantes:", error);
      toast("Erro ao criar participantes.", { type: "error" });
    }
  }

  const handleRemoveParticipant = async () => {
    const removedParticipant = getParticipants.filter(
      (origem) =>
        !participantes.some((participant) => participant.value === origem.value)
    );

    if (removedParticipant.length > 0) {
      const idsToRemove = removedParticipant.map((item) => item.value);

      try {
        const { data } = await api.post("/participantes/remove", {
          ids: idsToRemove,
        });

        // Atualiza a lista de participantes localmente se necessário
        removedParticipant.forEach((participant) => {
          const index = getParticipants.findIndex(
            (origem) => origem.value === participant.value
          );
          if (index > -1) {
            getParticipants.splice(index, 1);
          }
        });

        toast(data.msg, { type: "info" });
      } catch (error) {
        console.error("Erro ao remover participantes:", error);
        toast("Erro ao remover participantes.", { type: "error" });
      }
    }
  };

  const optionsParticipants = loginData.map((item) => {
    return {
      value: item.id,
      label: item.nome.toUpperCase(),
      email: item.email,
      nome: item.nome,
    };
  });

  return (
    <>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <ToastContainer />
        <div className={classes.paper}>
          {/*<img src={logo} className="App-logo" alt="logo" height="100" />*/}
          <Typography component="h1" variant="h5">
            Atendimento #{id}
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="Filial"
                  name="Filial"
                  variant="outlined"
                  required
                  fullWidth
                  id="filail"
                  color="secondary"
                  label="Filial"
                  value={data.filial || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="TipoAtendimento"
                  color="secondary"
                  label="Tipo de Atendimento"
                  name="TipoAtendimento"
                  value={tipoAtendimento.nome_atendimento || ""}
                  disabled
                  autoComplete="lname"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="data"
                  color="secondary"
                  label="Data da solicitação"
                  name="data"
                  value={data.data || ""}
                  disabled
                  autoComplete="data"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  color="secondary"
                  name="Status"
                  label="Status do Atendimento"
                  id="Status"
                  value={data.status || ""}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                {tipoAtendimento?.nome_atendimento === "Mudança de função" ? (
                  <MudancaFuncao id={id} />
                ) : (
                  ""
                )}
                {tipoAtendimento?.nome_atendimento === "Demissão" ? (
                  <Demissao id={id} />
                ) : (
                  ""
                )}
                {tipoAtendimento?.nome_atendimento === "Admissão" ? (
                  <Admissao id={id} />
                ) : (
                  ""
                )}
                {tipoAtendimento?.nome_atendimento === "Sanção disciplinar" && (
                  <SancaoDisciplinar id={id} />
                )}
                {tipoAtendimento?.nome_atendimento === "Atestados" && (
                  <Atestados id={id} />
                )}
                {tipoAtendimento?.nome_atendimento === "Premiações" && (
                  <Premiacao id={id} />
                )}
                {tipoAtendimento?.nome_atendimento === "Bônus" && (
                  <Bonus id={id} />
                )}
                {tipoAtendimento?.nome_atendimento === "Diversos" && (
                  <Diversos id={id} competencia={data?.competencia} />
                )}
              </Grid>

              {tipoAtendimento.nome_atendimento === "Fechamento de ponto" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="periodo"
                    id="periodo"
                    fullWidth
                    value={data?.competencia || ""}
                    label="Período"
                    variant="outlined"
                    disabled
                  />
                </Grid>
              )}

              {tipoAtendimento.nome_atendimento === "Benefícios" && (
                <Grid item xs={12}>
                  <TextField
                    id="movi_comp"
                    label="Benefício/Competência"
                    value={data?.competencia}
                    fullWidth
                    variant="outlined"
                    disabled
                  />
                </Grid>
              )}

              {typesServices.includes(tipoAtendimento.id) && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="competencia"
                    id="competencia"
                    fullWidth
                    value={data?.competencia || ""}
                    label="Competência"
                    variant="outlined"
                    disabled
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="Descricao"
                  color="secondary"
                  label="Descricao do Atendimento"
                  name="Descricao"
                  value={data.descricao || ""}
                  disabled
                  multiline
                  minRows="4"
                  autoComplete="lname"
                />
              </Grid>

              {data.cpf === null ? (
                ""
              ) : (
                <>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      color="secondary"
                      label={
                        data.status === "APROVADO"
                          ? "Faça um novo comentário"
                          : "Este atendimento ainda não foi aprovado"
                      }
                      multiline
                      //value={novoComentario}
                      onChange={(event) =>
                        setNovoComentario(event.target.value)
                      }
                      minRows="4"
                      disabled={data.status === "APROVADO" ? false : true}
                      variant="outlined"
                      autoFocus
                    />
                    <br />
                    <br />
                    {loginData.length > 0 && gestor && (
                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          color="secondary"
                        >
                          Alterar o agente responsável
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          variant="outlined"
                          label="Alterar o agente responsável"
                          onChange={handleChangeUser}
                          labelwidth={labelWidth}
                          value={data.user_responsavel || userAgente}
                          color="secondary"
                        >
                          {loginData.map((login) => (
                            <MenuItem key={login.id} value={login.id}>
                              {login.nome}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  {loginData.length > 0 && gestor && (
                    <>
                      <Grid item xs={10}>
                        <ReactSelect
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={optionsParticipants}
                          placeholder="Participantes..."
                          onChange={(value) => setParticipantes(value)}
                          value={participantes}
                          // onBlur={(e, value) =>
                          //   handleRemoveParticipant(e, value)
                          // }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Tooltip title="Adicionar Participantes.">
                          <Button
                            id="confirmar_participantes"
                            fullWidth
                            variant="contained"
                            onClick={createParticipants}
                          >
                            <Check />
                          </Button>
                        </Tooltip>
                      </Grid>
                    </>
                  )}
                </>
              )}

              {data.status === "APROVADO" || data.status === "PENDENTE" ? (
                <Grid item xs={12} sm={12}>
                  {data.cpf === null ? (
                    <TextField
                      id="filled-secondary"
                      label="Preencha o CPF"
                      variant="filled"
                      value={cpf}
                      onChange={(event) => setCpf(event.target.value)}
                      //type="number"
                      color="secondary"
                      inputProps={{ maxLength: 11 }}
                    />
                  ) : (
                    <div className={classes.root}>
                      <AppBar position="static" color="default">
                        <Tabs
                          variant="fullWidth"
                          value={value}
                          onChange={handleChange}
                          aria-label="nav tabs comemmt"
                        >
                          <LinkTab
                            label="Comentários"
                            href="/drafts"
                            {...a11yProps(0)}
                          />
                          <LinkTab
                            label="Enviar arquivos"
                            href="/trash"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={value} index={0}>
                        <Comentarios id={id} />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <FileUpload
                          cpf={data.cpf}
                          atendimento_id={id}
                          usuario_id={dataLocalStorage.id}
                          sendFile={true}
                        />
                      </TabPanel>
                    </div>
                  )}
                </Grid>
              ) : (
                ""
              )}

              {data.status === "APROVADO" || data.status === "PENDENTE" ? (
                <>
                  <Grid item xs={4} sm={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="inherit"
                      onClick={handleHistoryBack}
                      // className={classes.submit}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <ButtonNovo
                      type="submit"
                      id="buttonPost"
                      fullWidth
                      variant="contained"
                      color="primary"
                      // className={classes.submit}
                      onClick={
                        data.cpf === null ? handleChangeCpf : handleNewComment
                      }
                    >
                      {data.cpf === null ? "Salvar o CPF" : "Comentar"}
                    </ButtonNovo>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="inherit"
                    onClick={handleHistoryBack}
                    // className={classes.submit}
                  >
                    Voltar
                  </Button>
                </Grid>
              )}

              {(agente === true || gestor === true) &&
              data.status === "APROVADO" ? (
                <Grid item xs={4} sm={4}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="info"
                    onClick={handleCloseAtendimento}
                    className={classes.submit}
                  >
                    Finalizar Atendimento
                  </Button>
                </Grid>
              ) : (
                ""
              )}
            </Grid>
          </form>
        </div>
      </Container>
    </>
  );
}
