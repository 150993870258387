import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import api from "../../services/api";
import HorizontalLineWithText from "../HorizontalLineText";

export default function InteragirAtendimento({ id }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await api.get(`admissao/${id}`);
      setData(response.data);
    }
    fetchData();
  }, [id]);

  const renderAdmissionFields =
    data[0]?.tipo_admissao_id === 1 || data[0]?.tipo_admissao_id === 2;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <TextField
          autoComplete="Tipo de Movimentação"
          name="tipo_movimentação"
          variant="outlined"
          required
          fullWidth
          color="secondary"
          label="Tipo de Movimentação"
          value={data[0]?.tipo_admissao?.nome_tipo_admissao || ""}
          disabled
        />
      </Grid>

      {data[0]?.tipo_admissao_id === 2 && (
        <Grid item xs={12} sm={6}>
          <TextField
            id="matricula"
            name="matricula"
            label="Matrícula"
            fullWidth
            disabled
            value={data[0]?.matricula || ""}
            variant="outlined"
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          color="secondary"
          label={
            data[0]?.funcionario_subs === "" ? "Motivo" : "Nome Funcionário"
          }
          value={
            data[0]?.funcionario_subs === ""
              ? data[0]?.motivo
              : data[0]?.funcionario_subs || ""
          }
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="funcao"
          name="funcao"
          variant="outlined"
          required
          fullWidth
          color="secondary"
          label="Função"
          value={data[0]?.cargo || ""}
          disabled
        />
      </Grid>

      {data[0]?.tipo_admissao_id === 1 && (
        <>
          <Grid item xs={12}>
            <HorizontalLineWithText text="Novo Funcionário(a)" />
          </Grid>

          <Grid item xs={6}>
            <TextField
              id="nome"
              name="nome"
              label="Nome"
              variant="outlined"
              fullWidth
              disabled
              value={data[0].novo_funcionario}
            />
          </Grid>
        </>
      )}

      {data[0]?.tipo_admissao_id === 3 && (
        <Grid item xs={12}>
          <TextField
            id="filial_origem"
            name="filial_origem"
            variant="outlined"
            fullWidth
            label="Filial de Origem"
            disabled
            value={data[0]?.filial_origem}
          />
        </Grid>
      )}

      {renderAdmissionFields && (
        <>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="nova_funcao"
              name="nova_funcao"
              variant="outlined"
              required
              fullWidth
              color="secondary"
              label={data[0]?.tipo_admissao_id === 2 ? "Nova Função" : "Função"}
              value={data[0]?.nova_funcao || ""}
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="centro_custo"
              id="centro_custo"
              variant="outlined"
              fullWidth
              label="Centro de Custo"
              value={data[0]?.centro_custo || ""}
              disabled
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
